<template>
  <div class="home-page">
    <FirstScreen />
    <SecondScreen />
    <ComingSoon />
  </div>
</template>

<script>
import FirstScreen from '@/components/FirstScreen.vue'
import SecondScreen from '@/components/SecondScreen.vue'
import ComingSoon from '@/components/ComingSoon.vue'

export default {
  name: 'HomePage',
  components: {
    FirstScreen,
    SecondScreen,
    ComingSoon
  }
}
</script>

<style scoped>
.home-page {
  /* 如果需要，可以在这里添加样式 */
}
</style>
