<template>
  <div id="app">
    <nav>
      <select v-model="locale">
        <option value="zh">中文</option>
        <option value="en">English</option>
      </select>
    </nav>
    <router-view></router-view>
    <footer class="icp-footer">
      <p>
        版权所有 © 2002-2024，深圳艾克思人工智能有限公司 | 
        <a href="#" @click.prevent="goToAboutUs">关于我们</a> | 
        备案号: <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024306788号</a> | 
        <img src="@/assets/gongan.png" class="img-bei"> <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">粤公网安备：44030002004985号</a>
      </p>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'App',
  setup() {
    const router = useRouter()
    const { locale } = useI18n()

    const goToAboutUs = () => {
      router.push('/about-us')
    }

    return {
      locale,
      goToAboutUs
    }
  }
})
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}
.icp-footer {
  background-color: #f5f5f7;
  color: #86868b;
  text-align: center;
  padding: 15px 0;
  font-size: 12px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.icp-footer p {
  margin: 0;
  line-height: 1.5;
}
.img-bei {
    width: 14px;
    top: 2px;
    position: relative
}

@media (max-width: 768px) {
  .icp-footer {
    font-size: 10px;
    padding: 10px 0;
  }
}
select {
  padding: 5px 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

/* 在现有样式下添加以下内容 */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  min-height: 100%;
}

.screen {
  min-height: 100vh;
}
</style>
