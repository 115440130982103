<template>
  <section class="coming-soon">
    <div class="content">
      <h2>{{ $t('comingSoon.title') }}</h2>
      <p>{{ $t('comingSoon.description') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ComingSoon'
}
</script>

<style scoped>
.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.content {
  max-width: 800px;
  padding: 0 20px;
}

h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

p {
  font-size: 28px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  h2 {
    font-size: 36px;
  }

  p {
    font-size: 22px;
  }
}
</style>
