<template>
  <div class="screen second-screen">
    <div class="grid">
      <div v-for="(feature, index) in features" :key="index" class="feature">
        <h2>{{ $t(`secondScreen.features[${index}].title`) }}</h2>
        <p>{{ $t(`secondScreen.features[${index}].description`) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondScreen',
  data() {
    return {
      features: ['aiNoiseCancellation', 'personalizedSound']
    }
  }
}
</script>

<style scoped>
.second-screen {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
}

.grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.feature {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-bottom: 1rem;
  min-height: 60vh;
}

.feature:last-child {
  margin-bottom: 0;
}

.feature:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.feature:nth-child(1) { background-image: url('@/assets/feature-1.jpg'); }
.feature:nth-child(2) { background-image: url('@/assets/feature-2.jpg'); }

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

p {
  font-size: 1rem;
  text-align: center;
}

@media (min-width: 768px) {
  .grid {
    flex-direction: row;
    gap: 2rem;
  }

  .feature {
    flex: 1;
    margin-bottom: 0;
    min-height: 70vh;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }
}

@media (min-width: 1024px) {
  .second-screen {
    padding: 3rem;
  }

  .feature {
    padding: 4rem;
    min-height: 80vh;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.3rem;
  }
}

@media (min-width: 1440px) {
  .grid {
    max-width: 1600px;
  }

  .feature {
    min-height: 85vh;
  }
}
</style>
