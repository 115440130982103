<template>
  <div class="screen first-screen">
    <h1>{{ $t('firstScreen.title') }}</h1>
    <p>{{ $t('firstScreen.subtitle') }}</p>
  </div>
</template>

<script>
export default {
  name: 'FirstScreen'
}
</script>

<style scoped>
.first-screen {
  background-image: url('@/assets/first-screen-bg.webp');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

/* p {
  font-size: 1.5rem;
} */
p {
  font-size: 28px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .first-screen {
    background-image: url('@/assets/first-screen-bg-small.webp');
  }

  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.2rem;
  }
}
</style>
