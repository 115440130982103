<template>
  <div class="about-us">
    <h1>{{ $t('aboutUs.title') }}</h1>
    <div class="business-introduction">
      <h2>{{ $t('aboutUs.business.title') }}</h2>
      <p style="width: 60%; margin-left: auto; margin-right: auto;">{{ $t('aboutUs.business.description') }}</p>
    </div>
    <div class="mission-vision">
      <div class="mission">
        <h2>{{ $t('aboutUs.mission.title') }}</h2>
        <p>{{ $t('aboutUs.mission.description') }}</p>
      </div>
      <div class="vision">
        <h2>{{ $t('aboutUs.vision.title') }}</h2>
        <p>{{ $t('aboutUs.vision.description') }}</p>
      </div>
    </div>
    <div class="company-info">
      <h2>{{ $t('aboutUs.companyInfo.title') }}</h2>
      <p>{{ $t('aboutUs.companyInfo.address') }}</p>
      <p>{{ $t('aboutUs.companyInfo.phone') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped>
.about-us {
  padding: 20px;
  text-align: center;
}

.mission-vision {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.mission, .vision {
  width: 45%;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.company-info {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 95%; /* 修改宽度为90% */
  margin-left: auto; /* 居中 */
  margin-right: auto; /* 居中 */
}

.business-introduction {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 95%; /* 修改宽度为90% */
  margin-left: auto; /* 居中 */
  margin-right: auto; /* 居中 */
}
</style>
